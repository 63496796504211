import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import config from '../config';

const StatReport = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/admin/stat_report`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const processedData = markDuplicateStatRows(response.data);
        setStudents(processedData);
      } catch (error) {
        console.error('Error fetching stat report data:', error);
      }
    };

    fetchData();
  }, [navigate]);

  // Helper function to mark duplicates in the "r_stat" column
  const markDuplicateStatRows = (data) => {
    return data.map((item, index) => {
      if (index === 0 || item.r_stat !== data[index - 1].r_stat) {
        return { ...item, showStat: true }; // Display this cell
      }
      return { ...item, showStat: false }; // Hide duplicate
    });
  };

  const handleDownloadCSV = () => {
    const csvHeader = [
      '\uFEFFค่าสถิติ,ผู้ประเมิน,ด้านการจัดการเรียนรู้ (60 คะแนน),ด้านความสัมพันธ์กับผู้ปกครองและชุมชน (40 คะแนน),ด้านปฏิบัติหน้าที่ครูและจรรยาบรรณของวิชาชีพ (100 คะแนน)'
    ];
    const csvRows = students.map((student) =>
      [
        student.r_stat,
        student.r_evaluator,
        student.r_c1,
        student.r_c2,
        student.r_c3
      ]
    );

    const csvContent = csvHeader.concat(csvRows.map(row => row.join(','))).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'stat_report.csv');
  };

  const columns = [
    {
      field: 'r_stat',
      headerName: 'ค่าสถิติ',
      width: 150,
      renderCell: (params) => (
        params.row.showStat ? (
          <span style={{ fontWeight: 'bold' }}>{params.value}</span>
        ) : ''
      ),
    },
    { field: 'r_evaluator', headerName: 'ผู้ประเมิน', width: 200 },
    { field: 'r_c1', headerName: 'ด้านการจัดการเรียนรู้ (60 คะแนน)', width: 300 },
    { field: 'r_c2', headerName: 'ด้านความสัมพันธ์กับผู้ปกครองและชุมชน (40 คะแนน)', width: 350 },
    { field: 'r_c3', headerName: 'ด้านปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ (100 คะแนน)', width: 400 },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
            รายงาน ผลวิเคราะห์ทางสถิติ
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Button variant="contained" color="primary" onClick={handleDownloadCSV} sx={{ height: 'fit-content' }}>
              ดาวน์โหลด CSV
            </Button>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <DataGrid
            rows={students}
            getRowId={(row) => row.r_id}
            columns={columns}
            pagination
          />
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
};

export default StatReport;
// src/pages/EvaluatorEvaluationPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import EvaluatorEvaluationForm from '../components/EvaluatorEvaluationForm';
import Header from '../components/Header';
import Footer from '../components/Footer';
import config from '../config';
import { jwtDecode } from 'jwt-decode';

const EvaluatorEvaluationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { m_id_student, cr_id, s_id, round } = location.state || {};

  const [sections, setSections] = useState([]);
  const [initialRatings, setInitialRatings] = useState(undefined);

  // ตรวจสอบสิทธิ์ผู้ใช้
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const role = decodedToken.role;
      const allowedRoles = ['อาจารย์นิเทศก์', 'ครูพี่เลี้ยง', 'ผู้บริหารสถานศึกษา', 'ผู้แทนกรรมการสถานศึกษา'];
      if (!allowedRoles.includes(role)) {
        navigate('/');
      }
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('jwttoken');
      navigate('/');
    }
  }, [navigate]);

  // ดึงข้อมูลแบบฟอร์มการประเมินจาก backend
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) return;

    const fetchEvaluationData = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/job/getquestion`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { m_id_student, cr_id, s_id, round }
        });

        const formattedSections = Object.keys(response.data || {}).map((sectionTitle) => ({
          sectionTitle,
          criteria: response.data[sectionTitle].map((item) => ({
            criteriaTitle: item.cq_competency,
            question: item.cq_question,
            answer: item.answer
          }))
        }));
        
        setSections(formattedSections || []);
        setInitialRatings(formattedSections.reduce((acc, section) => {
          section.criteria.forEach((criteria) => {
            acc[criteria.cq_id] = criteria.answer || '';
          });
          return acc;
        }, {}));
      } catch (error) {
        console.error('Error fetching evaluation data:', error);
        setSections([]);
      }
    };

    fetchEvaluationData();
    //console.log(sections);
    //console.log(initialRatings);
  }, [m_id_student, cr_id, s_id, round]);

  return (
    <div>
      <Header />
      <EvaluatorEvaluationForm sections={sections} initialRatings={initialRatings} />
      <Footer />
    </div>
  );
};

export default EvaluatorEvaluationPage;
import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import config from '../config';
import './headercolor.css'; // เรียกใช้ไฟล์ CSS

const SummaryReport = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      const token = localStorage.getItem('jwttoken');
      if (!token) return;
      try {
        const response = await axios.get(`${config.backendUrl}/admin/summary_report`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching student report data:', error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleDownloadCSV = () => {
    const csvHeader = ['\uFEFFสมรรถนะหลัก,Min,Max,Range,Mean,Median,Mode,S.D.'];
    const csvRows = students.map((student) =>
      [student.r_c_type, student.Min, student.Max, student.Range, student.Mean, student.Median, student.Mode, student.SD]
    );

    const csvContent = csvHeader.concat(csvRows.map(row => row.join(','))).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'summary_report.csv');
  };

  const columns = [
    { field: 'r_c_type', headerName: 'สมรรถนะหลัก', width: 400 },
    { field: 'Min', headerName: 'Min', width: 100 },
    { field: 'Max', headerName: 'Max', width: 100 },
    { field: 'Range', headerName: 'Range', width: 150 },
    { field: 'Mean', headerName: 'Mean', width: 150 },
    { field: 'Median', headerName: 'Median', width: 150 },
    { field: 'Mode', headerName: 'Mode', width: 150 },
    { field: 'SD', headerName: 'S.D.', width: 150 }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
            รายงาน สรุปผลการประเมิน
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Button variant="contained" color="primary" onClick={handleDownloadCSV} sx={{ height: 'fit-content' }}>
              ดาวน์โหลด CSV
            </Button>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <DataGrid
            rows={students}
            getRowId={(row) => row.r_id}
            columns={columns}
            pagination
            getRowClassName={(params) =>
              params.id === students[students.length - 1].r_id ? 'last-row' : ''
            }
          />
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
};

export default SummaryReport;
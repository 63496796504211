// src/pages/EmailLoginPage.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Paper, Grid, Link, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const EmailLoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSendOtp = async () => {
    setIsSending(true);
    try {
      const response = await axios.post(`${config.backendUrl}/auth/require_login`, { email });
      setSnackbarMessage(response.data.message || 'OTP ถูกส่งไปยังอีเมลของคุณ');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      setTimeout(() => navigate('/otp'), 2000);
    } catch (err) {
      setSnackbarMessage(err.response?.data?.message || 'เกิดข้อผิดพลาดในการส่ง OTP');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') handleSendOtp();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason !== 'clickaway') setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundImage: 'url(/images/bg.jpg)', // เปลี่ยน path ให้ถูกต้อง
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: { xs: 2, md: 0 },
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={6}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // ใช้ column ในหน้าจอเล็ก
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          {/* ส่วนข้อมูลและโลโก้ทางซ้าย */}
          <Box
            sx={{
              flex: 1.5,
              p: { xs: 3, md: 4 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'left' }, // กึ่งกลางในหน้าจอเล็ก
            }}
          >
            <Typography variant="h5" sx={{ color: '#d70075', fontWeight: 'bold', mb: 2 }}>
              ระบบประเมินสมรรถนะวิชาชีพครู
            </Typography>
            <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
              ระบบประเมินความสามารถ ความรู้ ทักษะ และคุณลักษณะที่จำเป็นสำหรับการประกอบอาชีพครู สำหรับนักศึกษา อาจารย์นิเทศก์ ครูพี่เลี้ยง ผู้บริหารสถานศึกษา และผู้แทนกรรมการสถานศึกษา สามารถดาวน์โหลดคู่มือได้ที่ลิงก์ด้านล่าง:
            </Typography>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Link href="/path/to/student.pdf" download>
                  คู่มือ (นักศึกษา)
                </Link>
              </Grid>
              <Grid item>
                <Link href="/path/to/evaluator.pdf" download>
                  คู่มือ (ผู้ประเมิน)
                </Link>
              </Grid>
              <Grid item>
                <Link href="/path/to/admin.pdf" download>
                  คู่มือ (ผู้ดูแลระบบ)
                </Link>
              </Grid>
            </Grid>
            <Typography variant="body2" sx={{ mt: 4, color: '#333' }}>
              หากมีข้อสงสัยติดต่อได้ที่ งานสหกิจศึกษา คณะครุศาสตร์อุตสาหกรรม มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร โทร 02-665-3777 ต่อ 8244
            </Typography>
          </Box>

          {/* ส่วน Login ทางขวา */}
          <Box
            sx={{
              flex: 1,
              p: { xs: 3, md: 4 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderLeft: { md: '1px solid #e0e0e0' }, // ใช้เส้นเฉพาะในหน้าจอใหญ่
            }}
          >
            {/* พื้นที่สำหรับโลโก้ */}
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <img src="/images/teched.png" alt="Organization Logo" style={{ maxWidth: '120px' }} />
            </Box>
            <Typography variant="h5" sx={{ color: '#d70075', fontWeight: 'bold', mb: 1, textAlign: 'center' }}>
              เข้าสู่ระบบ
            </Typography>
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              sx={{ mb: 3 }}
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyPress}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={handleSendOtp}
              disabled={isSending}
              sx={{
                backgroundColor: '#d70075',
                ':hover': { backgroundColor: '#ad005d' },
                height: 50,
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
            >
              {isSending ? 'Sending OTP...' : 'Login'}
            </Button>
          </Box>
        </Paper>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmailLoginPage;
import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, Paper, LinearProgress, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PieChartIcon from '@mui/icons-material/PieChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import config from '../config';
import { jwtDecode } from 'jwt-decode';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    const fetchSummaryData = async () => {
      const token = localStorage.getItem('jwttoken'); // ดึง token จาก localStorage
      if (!token) {
        navigate('/login'); // ถ้าไม่มี token ให้ redirect ไปหน้า login
        return;
      }

      try {
        const decodedToken = jwtDecode(token); // ตรวจสอบ token
        if (Date.now() / 1000 > decodedToken.exp) { // เช็คว่า token หมดอายุหรือยัง
          localStorage.removeItem('jwttoken');
          navigate('/login');
          return;
        }

        const response = await axios.get(`${config.backendUrl}/admin/esummary`, {
          headers: {
            Authorization: `Bearer ${token}`, // ส่ง token ใน header
          },
        });
        setSummaryData(response.data);
      } catch (error) {
        console.error('Error fetching summary data:', error);
      }
    };
    fetchSummaryData();
  }, [navigate]);

  if (!summaryData) {
    return (
      <div>
        <Header />
        <Container maxWidth="lg" sx={{ mt: 5, mb: 5, textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>กำลังโหลดข้อมูล...</Typography>
        </Container>
        <Footer />
      </div>
    );
  }

  const { s_amount, ef_percent } = summaryData;

  return (
    <div>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
        {/* Title */}
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          รายงาน (ระบบประเมินสมรรถนะวิชาชีพครู)
        </Typography>

        {/* แสดงข้อมูลสถิติ */}
        <Grid container spacing={3} sx={{ mt: 4 }}>
          {/* จำนวนนักศึกษาทั้งหมด */}
          <Grid item xs={12} md={3}>
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              textAlign: 'center',
              backgroundColor: '#f3e5f5',
              minHeight: 85, // เพิ่มความสูงเพื่อให้ดูสมดุล
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => navigate('/evaluation-incomplete')}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: '#000000', // เลือกสีที่สวยงาม
                mb: 1, // margin bottom เล็กน้อยให้ห่างจากตัวเลข
              }}
            >
              จำนวนนักศึกษา
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                color: '#8e24aa', // สีที่เข้ากับพื้นหลังและมีความชัดเจน
              }}
            >
              {s_amount}
            </Typography>
          </Paper>
        </Grid>

            {/* แบบประเมินที่ยังไม่ทำ */}
            <Grid item xs={12} md={9}>
              <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', backgroundColor: '#fff3e0', minHeight: 85 }} onClick={() => navigate('/evaluation-incomplete')}>
                <ChecklistIcon sx={{ fontSize: '3rem', color: '#e65100' }} />
                <Typography variant="h6">ตรวจสอบการประเมิน</Typography>
              </Paper>
            </Grid>
      </Grid>
        {/* แสดงอัตรา % การประเมินที่เสร็จแล้ว */}
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0f7fa' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#00796b' }}>
              ติดตามความคืบหน้า
            </Typography>
            <Box sx={{ width: '80%', mt: 2, mx: 'auto' }}>
              <LinearProgress
                variant="determinate"
                value={parseFloat(ef_percent)}
                sx={{ height: 20, borderRadius: 5, backgroundColor: '#b2dfdb' }}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                ส่งแบบประเมินแล้ว {ef_percent}%
              </Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Icon buttons สำหรับหมวดหมู่ */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={3}>
            <Paper
              elevation={3}
              sx={{ padding: 3, textAlign: 'center', backgroundColor: '#e0f7fa', cursor: 'pointer' }}
              onClick={() => navigate('/student-report')}
            >
              <AccountBoxIcon sx={{ fontSize: '3rem', color: '#00796b' }} />
              <Typography variant="h6">ข้อมูลนักศึกษา</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', backgroundColor: '#fff3e0' }} onClick={() => navigate('/sumscore-report')}>
              <StarBorderIcon sx={{ fontSize: '3rem', color: '#e65100' }} />
              <Typography variant="h6">คะแนนประเมินรายด้าน</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', backgroundColor: '#f3e5f5' }} onClick={() => navigate('/avgscore-report')} >
              <EqualizerIcon sx={{ fontSize: '3rem', color: '#8e24aa' }} />
              <Typography variant="h6">คะแนนประเมินเฉลี่ย</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', backgroundColor: '#e1f5fe' }} onClick={() => navigate('/stat-report')}>
              <PieChartIcon sx={{ fontSize: '3rem', color: '#0277bd' }} />
              <Typography variant="h6">ผลวิเคราะห์ทางสถิติ</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', backgroundColor: '#f9fbe7' }} onClick={() => navigate('/summary-report')}>
              <SummarizeIcon sx={{ fontSize: '3rem', color: '#afb42b' }} />
              <Typography variant="h6">สรุปผลการประเมิน</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
// src/pages/MemberManagement.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  Button,
  Paper,
  Tabs,
  Tab,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Snackbar,
  Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const MemberManagement = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    m_id: '',
    prefix: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    school: '',
    isAdmin: 'no',
    status: 'yes',
  });
  const [members, setMembers] = useState({ students: [], evaluators: [], administrators: [] });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedFile, setSelectedFile] = useState(null); // เพิ่ม state สำหรับไฟล์ CSV

  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/login');
      return;
    }

    const decodedToken = jwtDecode(token);
    if (decodedToken.isadmin !== 'Y') {
      alert('คุณไม่มีสิทธิ์เข้าถึงหน้านี้');
      navigate('/');
      return;
    }

    const fetchMembers = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/admin/get_member`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;

        const categorizedMembers = {
          students: [],
          evaluators: [],
          administrators: [],
        };

        data.forEach((member, index) => {
          const newMember = {
            id: index + 1,
            m_id: member.m_id,
            prefix: member.m_prefix,
            firstName: member.m_name,
            lastName: member.m_surname,
            email: member.m_email,
            role: member.m_role,
            school: member.m_school,
            isAdmin: member.isadmin === 'Y' ? 'yes' : 'no',
            //status: member.m_disable ? 'Disabled' : 'Active',
            status: member.m_disable === 'Y' ? 'yes' : 'no',
          };

          if (member.isadmin === 'Y') {
            categorizedMembers.administrators.push(newMember);
          } else if (member.m_role === 'นักศึกษา') {
            categorizedMembers.students.push(newMember);
          } else {
            categorizedMembers.evaluators.push(newMember);
          }
        });

        setMembers(categorizedMembers);
      } catch (error) {
        console.error('Error fetching member data:', error);
      }
    };

    fetchMembers();
  }, [navigate]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const columns = [
    { field: 'prefix', headerName: 'คำนำหน้าชื่อ', width: 100 },
    { field: 'firstName', headerName: 'ชื่อ', width: 200 },
    { field: 'lastName', headerName: 'นามสกุล', width: 200 },
    { field: 'role', headerName: 'บทบาท', width: 120 },
    { field: 'school', headerName: 'สถานศึกษา', width: 300 },
    {
      field: 'actions',
      headerName: 'จัดการ',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleOpenDialog(params.row, true)} // เปิด Dialog สำหรับการแก้ไข
        >
          แก้ไข
        </Button>
      ),
    },
    {
      field: 'status',
      headerName: 'สถานะ',
      width: 100,
      renderCell: (params) => (
        <Switch
          checked={params.row.status === 'no'}
          //onChange={() =>
          //  handleToggleStatus(
          //    activeTab === 0 ? 'students' : activeTab === 1 ? 'evaluators' : 'administrators',
          //    params.m_id
          //  )
          //}
          color="primary"
        />
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  //const filteredMembers = members[
  //  activeTab === 0 ? 'students' : activeTab === 1 ? 'evaluators' : 'administrators'
  //].filter((member) =>
  //  member.firstName.toLowerCase().includes(searchQuery.toLowerCase())
  //);

  const filteredMembers = members[
    activeTab === 0 ? 'students' : activeTab === 1 ? 'evaluators' : 'administrators'
  ].filter((member) =>
    member.firstName ? member.firstName.toLowerCase().includes(searchQuery.toLowerCase()) : false
  );

  const handleOpenDialog = (member, isEditMode = false) => {
    setEditMode(isEditMode);
    setFormData({
      m_id: member?.m_id || '', // ใช้ member?.m_id แทน member?.id
      prefix: member?.prefix || '',
      firstName: member?.firstName || '',
      lastName: member?.lastName || '',
      email: member?.email || '',
      role: member?.role || '',
      school: member?.school || '',
      isAdmin: member?.isAdmin || 'no',
      status: member?.status || 'yes',
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('jwttoken');
    const endpoint = editMode ? '/admin/update_member' : '/admin/create_member';
    const method = editMode ? 'put' : 'post';
    console.log("FormData being sent:", formData); // คำสั่ง console.log เพื่อแสดงข้อมูล formData
    try {
        const response = await axios[method](
            `${config.backendUrl}${endpoint}`,
            {
                m_id: editMode ? formData.m_id : undefined,
                m_prefix: formData.prefix,
                m_name: formData.firstName,
                m_surname: formData.lastName,
                m_email: editMode ? undefined : formData.email,
                m_role: formData.role,
                m_school: formData.school,
                m_isadmin: formData.isAdmin === 'yes' ? 'Y' : 'N',
                m_disable: formData.status === 'yes' ? 'Y' : 'N',
            },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.message) { // ปรับเงื่อนไขตรงนี้
            setSnackbarMessage(response.data.message);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setOpenDialog(false); // ปิด Dialog หลังจากอัพเดทสำเร็จ
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } else {
            setSnackbarMessage('เกิดข้อผิดพลาด');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    } catch (error) {
        setSnackbarMessage(error.message || 'เกิดข้อผิดพลาดในการบันทึก');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    }
};

//const handleSnackbarClose = (event, reason) => {
//    if (reason === 'clickaway') {
//        return;
//    }
//    setSnackbarOpen(false);
//};

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      setSelectedFile(file);
      setSnackbarMessage('ไฟล์ CSV พร้อมสำหรับการอัปโหลด');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage('กรุณาเลือกไฟล์ CSV ที่ถูกต้อง');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setSelectedFile(null);
    }
  };

  const handleImportCSV = async () => {
    if (!selectedFile) {
      setSnackbarMessage('โปรดเลือกไฟล์ CSV ก่อนอัปโหลด');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = localStorage.getItem('jwttoken');
      const response = await axios.post(`${config.backendUrl}/admin/upload_member`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setSnackbarMessage(response.data.message || 'อัปโหลดไฟล์สำเร็จ');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setSelectedFile(null);
      setTimeout(() => {
        window.location.reload();
    }, 2000);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'การอัปโหลดไฟล์ล้มเหลว');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setTimeout(() => {
        window.location.reload();
    }, 2000);
    }
  };
  
  return (
    <div>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          จัดการสมาชิก (Member Management)
        </Typography>

        <Box sx={{ mb: 2, textAlign: 'right' }}>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenDialog(null, false)}>
            เพิ่มสมาชิก
          </Button>
        </Box>

        <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: '#f5f5f5' }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>นำเข้าข้อมูลสมาชิกจาก CSV</Typography>
              <Button variant="contained" component="label" color="primary" startIcon={<UploadFileIcon />} sx={{ mt: 2 }}>
                Browse CSV File
                <input type="file" accept=".csv" hidden onChange={handleFileChange} />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleImportCSV}
                startIcon={<UploadFileIcon />}
                sx={{ mt: 2, ml: 2 }}
                disabled={!selectedFile}
              >
                Import CSV
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                ดาวน์โหลด Template CSV
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    href="/templates/student_template.csv"
                    download
                  >
                    ข้อมูลนักศึกษา
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    href="/templates/evaluator_template.csv"
                    download
                  >
                    ข้อมูลผู้ประเมิน
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Box sx={{ mb: 4, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
          >
            <Tab label="นักศึกษา" />
            <Tab label="ผู้ประเมิน" />
            <Tab label="ผู้ดูแลระบบ" />
          </Tabs>
        </Box>

        <TextField
          label="ค้นหาชื่อ"
          variant="outlined"
          fullWidth
          sx={{ mb: 3 }}
          value={searchQuery}
          onChange={handleSearchChange}
        />

        <Paper elevation={3} sx={{ height: 400, p: 2 }}>
          <DataGrid
            rows={filteredMembers}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Paper>
      </Container>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? 'แก้ไขข้อมูลสมาชิก' : 'เพิ่มสมาชิกใหม่'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="คำนำหน้าชื่อ"
            name="prefix"
            value={formData.prefix}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            select
          >
            {['นาย', 'นาง', 'นางสาว'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="ชื่อ"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="นามสกุล"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="อีเมล"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            disabled={editMode} // ปิดการแก้ไขอีเมลเมื่อเป็นโหมดแก้ไข
          />
          <TextField
            margin="dense"
            label="บทบาท"
            name="role"
            value={formData.role}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            select
          >
            {['อาจารย์นิเทศก์', 'ครูพี่เลี้ยง', 'ผู้บริหารสถานศึกษา', 'นักศึกษา', 'ผู้แทนกรรมการสถานศึกษา'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="ชื่อสถานศึกษาที่ปฏิบัติการสอน"
            name="school"
            value={formData.school}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="สิทธิ์ระดับผู้ดูแล"
            name="isAdmin"
            value={formData.isAdmin}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            select
          >
            {['yes', 'no'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="ระงับผู้ใช้"
            name="status"
            value={formData.status}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            select
          >
            {['yes', 'no'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            ยกเลิก
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editMode ? 'บันทึก' : 'เพิ่ม'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
    open={snackbarOpen}
    autoHideDuration={2000}
    onClose={() => {
        setSnackbarOpen(false);
        setSnackbarSeverity('success'); // รีเซ็ตเป็น success หลังปิด
    }}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
>
    <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
    </Alert>
</Snackbar>
      <Footer />
    </div>
  );
};

export default MemberManagement;
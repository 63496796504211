// src/pages/EvalIncompleteReport.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Paper, Grid, Divider } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { saveAs } from 'file-saver';
import config from '../config';
import './headercolor.css';


const EvalIncompleteReport = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [overview, setOverview] = useState({
    totalStudents: 0,
    totalSchools: 0,
    majorSubjects: {},
  });

  // ตรวจสอบการล็อกอินและโหลดข้อมูล
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      if (Date.now() / 1000 > decodedToken.exp) {
        localStorage.removeItem('jwttoken');
        navigate('/');
        return;
      }
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('jwttoken');
      navigate('/');
      return;
    }

    // ดึงข้อมูลนักศึกษา
    const fetchData = async () => {
      const token = localStorage.getItem('jwttoken');
      if (!token) return;
      try {
        const response = await axios.get(`${config.backendUrl}/admin/evaluation_incomplete`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        //console.log("Data received:", JSON.stringify(response.data, null, 2));
        setStudents(response.data);
        calculateOverview(response.data);
      } catch (error) {
        console.error('Error fetching student report data:', error);
      }
    };

    fetchData();
  }, [navigate]);

  // ฟังก์ชันคำนวณภาพรวมข้อมูล
  const calculateOverview = (data) => {
    const totalStudents = data.length;
    setOverview({ totalStudents });
  };

    // ฟังก์ชันดาวน์โหลด CSV
    const handleDownloadCSV = () => {
        const csvHeader = [
        '\uFEFFชื่อผู้ประเมิน,นามสกุลผู้ประเมิน,บทบาท,ชื่อผู้ถูกประเมิน,นามสกุลผู้ถูกประเมิน,สถานศึกษา,ผลประเมินรอบที่ 1,ผลประเมินรอบที่ 2,ผลประเมินรอบที่ 3'
        ];
        const csvRows = students.map((student) => [
        student.m_name,
        student.m_surname,
        student.m_role,
        student.s_name,
        student.s_surname,
        student.s_teaching_school,
        student.round1,
        student.round2,
        student.round3
        ]);
    
        const csvContent = csvHeader.concat(csvRows.map(row => row.join(','))).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'evaluation_incomplete.csv');
    };

  // Column Definitions
  const columns = [
    { field: 'm_name', headerName: 'ชื่อผู้ประเมิน', width: 150 },
    { field: 'm_surname', headerName: 'นามสกุลผู้ประเมิน', width: 150 },
    { field: 'm_role', headerName: 'บทบาท', width: 150 },
    { field: 's_name', headerName: 'ชื่อผู้ถูกประเมิน', width: 150 },
    { field: 's_surname', headerName: 'นามสกุลผู้ถูกประเมิน', width: 150 },
    { field: 's_teaching_school', headerName: 'สถานศึกษา', width: 200 },
    {
      field: 'round1',
      headerName: 'ประเมินรอบที่ 1',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:
              params.value === 100 ? '#00bf63' :
              params.value >= 50 ? '#ffde59' :
              params.value >= 20 ? '#ffa945' : '#ff3131',
            color: params.value === 100 ? 'white' : 'black',
          }}
        >
          {params.value} %
        </Box>
      ),
    },
    {
      field: 'round2',
      headerName: 'ประเมินรอบที่ 2',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:
              params.value === 100 ? '#00bf63' :
              params.value >= 50 ? '#ffde59' :
              params.value >= 20 ? '#ffa945' : '#ff3131',
            color: params.value === 100 ? 'white' : 'black',
          }}
        >
          {params.value}%
        </Box>
      ),
    },
    {
      field: 'round3',
      headerName: 'ประเมินรอบที่ 3',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:
              params.value === 100 ? '#00bf63' :
              params.value >= 50 ? '#ffde59' :
              params.value >= 20 ? '#ffa945' : '#ff3131',
            color: params.value === 100 ? 'white' : 'black',
          }}
        >
          {params.value}%
        </Box>
      ),
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
            รายงาน ตรวจสอบการประเมิน
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Box>
              <Typography variant="h6" sx={{ color: '#2c3e50', fontWeight: 'medium' }}>ข้อมูลภาพรวม</Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={6} sm={12}>
                  <Typography variant="body1">จำนวนการประเมิน (นักศึกษาที่เลือกผู้ประเมินแล้ว) : {overview.totalStudents} </Typography>
                </Grid>
              </Grid>
            </Box>
            <Button variant="contained" color="primary" onClick={handleDownloadCSV} sx={{ height: 'fit-content' }}>
              ดาวน์โหลด CSV
            </Button>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <DataGrid
            rows={students} // แทน data ด้วยข้อมูลของคุณ
            getRowId={(row) => row.id}
            columns={columns.map(col => ({
              ...col,
              headerClassName: 'header-custom' // Apply class to headers
            }))}
            pagination
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'lightblue', // Set the background color
                color: 'black',               // Set the font color
                fontSize: '1rem',             // Set font size
                fontWeight: 'bold',           // Make text bold
              },
            }}
            />
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
};

export default EvalIncompleteReport
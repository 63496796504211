import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import config from '../config';

const EvaluatorPendingJob = () => {
  const [students, setStudents] = useState([]);
  const navigate = useNavigate();

  // ตรวจสอบสิทธิ์ผู้ใช้
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const role = decodedToken.role;
      const allowedRoles = ['อาจารย์นิเทศก์', 'ครูพี่เลี้ยง', 'ผู้บริหารสถานศึกษา', 'ผู้แทนกรรมการสถานศึกษา'];
      if (!allowedRoles.includes(role)) {
        navigate('/');
      }
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('jwttoken');
      navigate('/');
    }
  }, [navigate]);

  // ฟังก์ชันดึงข้อมูลนักศึกษาจาก backend
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) return;

    const decodedToken = jwtDecode(token);
    const m_id = decodedToken.m_id;

    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/job/getjobpending`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { m_id },
        });
        setStudents(Array.isArray(response.data) ? response.data : []);
        //console.log(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
        setStudents([]);
      }
    };
    fetchStudents();
  }, []);

  // ปรับการนำทางไปยังหน้า /evaluator-evaluation พร้อมด้วยพารามิเตอร์
const handleEvaluate = (m_id_student, cr_id, s_id, round) => {
  navigate(`/evaluator-evaluation`, { state: { m_id_student, cr_id, s_id, round } });
};

const columns = [
  {
    name: 'ชื่อ',
    selector: (row) => row.s_name,
    sortable: true,
  },
  {
    name: 'นามสกุล',
    selector: (row) => row.s_surname,
    sortable: true,
  },
  {
    name: 'ชื่อสถานศึกษาที่ปฏิบัติการสอน',
    selector: (row) => row.s_teaching_school,
    sortable: true,
    width: '250px',
  },
  {
    name: 'ประเมินรอบที่ 1',
    cell: (row) => (
      <Button
        variant="contained"
        sx={{
          backgroundColor: row.r1_submit_date ? '#70c14e' : '#1976d2',
          color: '#fff',
          ':hover': { backgroundColor: row.r1_submit_date ? '#5da93c' : '#115292' },
        }}
        onClick={() => handleEvaluate(row.m_id_student, row.cr_id, row.s_id, 1)}
      >
        {row.r1_submit_date ? 'ดูผลประเมิน' : 'ทำแบบประเมิน'}
      </Button>
    ),
  },
  {
    name: 'ประเมินรอบที่ 2',
    cell: (row) => (
      <Button
        variant="contained"
        sx={{
          backgroundColor: row.r2_submit_date ? '#6CC24A' : row.r1_submit_date ? '#1976d2' : '#70c14e',
          color: '#fff',
          ':hover': { backgroundColor: row.r2_submit_date ? '#5BAA3C' : row.r1_submit_date ? '#115292' : '#5da93c' },
        }}
        onClick={() => handleEvaluate(row.m_id_student, row.cr_id, row.s_id, 2)}
        disabled={!row.r1_submit_date}
      >
        {row.r2_submit_date ? 'ดูผลประเมิน' : 'ทำแบบประเมิน'}
      </Button>
    ),
  },
  {
    name: 'ประเมินรอบที่ 3',
    cell: (row) => (
      <Button
        variant="contained"
        sx={{
          backgroundColor: row.r3_submit_date ? '#6CC24A' : row.r2_submit_date ? '#1976d2' : '#70c14e',
          color: '#fff',
          ':hover': { backgroundColor: row.r3_submit_date ? '#5BAA3C' : row.r2_submit_date ? '#115292' : '#5da93c' },
        }}
        onClick={() => handleEvaluate(row.m_id_student, row.cr_id, row.s_id, 3)}
        disabled={!row.r2_submit_date}
      >
        {row.r3_submit_date ? 'ดูผลประเมิน' : 'ทำแบบประเมิน'}
      </Button>
    ),
  },
];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#f2f0f1',
        color: '#d70075',
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        padding: '8px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: '#f1f1f1',
      },
    },
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header */}
      <Header />

      {/* Content */}
      <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold', textAlign: 'center' }}>
          รายชื่อนักศึกษาที่รอการประเมิน
        </Typography>
        <DataTable
          columns={columns}
          data={students}
          pagination
          highlightOnHover
          customStyles={customStyles}
        />
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default EvaluatorPendingJob;
// src/components/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#d70075', padding: 2, mt: 'auto', textAlign: 'center' }}>
      <Typography variant="body2" color="#ffffff">
        © 2024 ระบบประเมินสมรรถนะทางวิชาชีพครู มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร
      </Typography>
    </Box>
  );
};

export default Footer;
// src/pages/EvaluatorPortal.js
import React, { useState } from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';

const EvaluatorPortal = () => {
  const [isRound1Completed, setRound1Completed] = useState(false);
  const [isRound2Completed, setRound2Completed] = useState(false);

  const handleRound1 = () => {
    setRound1Completed(true);
  };

  const handleRound2 = () => {
    if (isRound1Completed) {
      setRound2Completed(true);
    }
  };

  const handleRound3 = () => {
    // Logic สำหรับประเมินรอบ 3
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header */}
      <Header />

      {/* Content */}
      <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5, mb: 5 }}>
        <Paper elevation={6} sx={{ padding: 4, backgroundColor: '#f8f9fa', borderRadius: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
            Evaluator Portal
          </Typography>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRound1}
              sx={{ flex: 1 }}
            >
              ประเมินรอบ 1
            </Button>

            <Button
              variant="contained"
              color={isRound1Completed ? 'primary' : 'grey'}
              onClick={handleRound2}
              disabled={!isRound1Completed}
              sx={{ flex: 1 }}
            >
              ประเมินรอบ 2
            </Button>

            <Button
              variant="contained"
              color={isRound2Completed ? 'primary' : 'grey'}
              onClick={handleRound3}
              disabled={!isRound2Completed}
              sx={{ flex: 1 }}
            >
              ประเมินรอบ 3
            </Button>
          </Box>
        </Paper>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default EvaluatorPortal;
// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SystemLandingPage from './pages/SystemLandingPage';
import EmailLoginPage from './pages/EmailLoginPage';
import OtpPage from './pages/OtpPage';
import StudentProfileUpdate from './pages/StudentProfileUpdate';
import EvaluatorPortal from './pages/EvaluatorPortal';
import EvaluatorPendingJob from './pages/EvaluatorPendingJob';
import EvaluatorEvaluationPage from './pages/EvaluatorEvaluationPage';
import AdminDashboard from './pages/AdminDashboard';
import MemberManagement from './pages/MemberManagement'; // Import หน้า MemberManagement
import StudentReportPage from './pages/StudentReportPage';
import StudentInfo from './pages/StudentInfo';
import EvaluationScore from './pages/EvaluationScore';
import SumscoreReport from './pages/SumscoreReport';
import AvgscoreReport from './pages/AvgscoreReport';
import StatReport from './pages/StatReport';
import SummaryReport from './pages/SummaryReport';
import EvalIncompleteReport from './pages/EvalIncompleteReport';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EmailLoginPage />} />
        <Route path="/login" element={<EmailLoginPage />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/student-profile-update" element={<StudentProfileUpdate />} />
        <Route path="/evaluator-portal" element={<EvaluatorPortal />} />
        <Route path="/evaluator-pending-job" element={<EvaluatorPendingJob />} />
        <Route path="/evaluator-evaluation" element={<EvaluatorEvaluationPage />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/member-management" element={<MemberManagement />} /> {/* เส้นทางสำหรับ MemberManagement */}
        <Route path="/student-report" element={<StudentReportPage />} />
        <Route path="/sumscore-report" element={<SumscoreReport />} />
        <Route path="/avgscore-report" element={<AvgscoreReport />} />
        <Route path="/stat-report" element={<StatReport />} />
        <Route path="/summary-report" element={<SummaryReport />} />
        <Route path="/student-info" element={<StudentInfo />} />
        <Route path="/evaluation-score" element={<EvaluationScore />} />
        <Route path="/evaluation-incomplete" element={<EvalIncompleteReport />} />
      </Routes>
    </Router>
  );
}

export default App;
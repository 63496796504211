// src/pages/StudentReportPage.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Paper, Grid, Divider } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { saveAs } from 'file-saver';
import config from '../config';


const StudentReport = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [overview, setOverview] = useState({
    totalStudents: 0,
    totalSchools: 0,
    majorSubjects: {},
  });

  // ตรวจสอบการล็อกอินและโหลดข้อมูล
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      if (Date.now() / 1000 > decodedToken.exp) {
        localStorage.removeItem('jwttoken');
        navigate('/');
        return;
      }
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('jwttoken');
      navigate('/');
      return;
    }

    // ดึงข้อมูลนักศึกษา
    const fetchData = async () => {
      const token = localStorage.getItem('jwttoken');
      if (!token) return;
      try {
        const response = await axios.get(`${config.backendUrl}/admin/student_report`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStudents(response.data);
        calculateOverview(response.data);
      } catch (error) {
        console.error('Error fetching student report data:', error);
      }
    };

    fetchData();
  }, [navigate]);

  // ฟังก์ชันคำนวณภาพรวมข้อมูล
  const calculateOverview = (data) => {
    const totalStudents = data.length;
    setOverview({ totalStudents });
  };

  // ฟังก์ชันดาวน์โหลด CSV
  const handleDownloadCSV = () => {
    const csvHeader = [
      '\uFEFFรหัสนักศึกษา,เลขประจำตัวประชาชน,คำนำหน้า,ชื่อ,นามสกุล,อีเมล,เบอร์โทร,โรงเรียนที่สอน,อำเภอ,จังหวัด,สังกัด,ขนาดโรงเรียน,วิชาเอก,กลุ่มวิชาที่สอน,วิชาที่สอน,ความสัมพันธ์ของวิชาเอกกับวิชาที่สอน,คำนำหน้าชื่ออาจารย์นิเทศก์,ชื่ออาจารย์นิเทศก์,นามสกุลอาจารย์นิเทศก์,คำนำหน้าชื่อครูพี่เลี้ยง,ชื่อครูพี่เลี้ยง,นามสกุลครูพี่เลี้ยง,คำนำหน้าชื่อผู้บริหารสถานศึกษา,ชื่อผู้บริหารสถานศึกษา,นามสกุลผู้บริหารสถานศึกษา,คำนำหน้าชื่อผู้แทนกรรมการสถานศึกษา,ชื่อผู้แทนกรรมการสถานศึกษา,นามสกุลผู้แทนกรรมการสถานศึกษา'
    ];
    const csvRows = students.map((student) =>
      [
        student.s_code,
        student.s_citizen_id,
        student.s_prefix,
        student.s_name,
        student.s_surname,
        student.s_email,
        student.s_phone,
        student.s_teaching_school,
        student.s_teaching_district,
        student.s_teaching_province,
        student.s_school_affiliation,
        student.s_school_size,
        student.s_major_subject,
        student.s_teaching_subject_group,
        student.s_teaching_subject,
        student.s_major_subject_relation,
        student.supervisor_prefix,
        student.supervisor_name,
        student.supervisor_surname,
        student.teacher_prefix,
        student.teacher_name,
        student.teacher_surname,
        student.ceo_prefix,
        student.ceo_name,
        student.ceo_surname,
        student.agent_prefix,
        student.agent_name,
        student.agent_surname
    ]);

    const csvContent = csvHeader.concat(csvRows.map(row => row.join(','))).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'student_report.csv');
  };

  const columns = [
    { field: 's_id', headerName: 'รหัส', width: 100 },
    { field: 's_code', headerName: 'รหัสนักศึกษา', width: 150 },
    { field: 's_citizen_id', headerName: 'เลขบัตรประชาชน', width: 150 },
    { field: 's_prefix', headerName: 'คำนำหน้า', width: 100 },
    { field: 's_name', headerName: 'ชื่อ', width: 150 },
    { field: 's_surname', headerName: 'นามสกุล', width: 150 },
    { field: 's_email', headerName: 'อีเมล', width: 200 },
    { field: 's_phone', headerName: 'โทรศัพท์', width: 150 },
    { field: 's_teaching_school', headerName: 'สถานศึกษา', width: 200 },
    { field: 's_teaching_district', headerName: 'อำเภอ', width: 150 },
    { field: 's_teaching_province', headerName: 'จังหวัด', width: 150 },
    { field: 's_school_affiliation', headerName: 'สังกัดสถานศึกษา', width: 250 },
    { field: 's_school_size', headerName: 'ขนาดสถานศึกษา', width: 150 },
    { field: 's_major_subject', headerName: 'วิชาเอก', width: 150 },
    { field: 's_teaching_subject_group', headerName: 'กลุ่มสาระการเรียนรู้', width: 200 },
    { field: 's_teaching_subject', headerName: 'วิชาที่สอน', width: 150 },
    { field: 's_major_subject_relation', headerName: 'ความสัมพันธ์ของวิชาเอก', width: 250 },
    { field: 'supervisor_prefix', headerName: 'คำนำหน้าอาจารย์นิเทศก์', width: 100 },
    { field: 'supervisor_name', headerName: 'ชื่ออาจารย์นิเทศก์', width: 150 },
    { field: 'supervisor_surname', headerName: 'นามสกุลอาจารย์นิเทศก์', width: 150 },
    { field: 'teacher_prefix', headerName: 'คำนำหน้าครูพี่เลี้ยง', width: 100 },
    { field: 'teacher_name', headerName: 'ชื่อครูพี่เลี้ยง', width: 150 },
    { field: 'teacher_surname', headerName: 'นามสกุลครูพี่เลี้ยง', width: 150 },
    { field: 'ceo_prefix', headerName: 'คำนำหน้าผู้บริหาร', width: 100 },
    { field: 'ceo_name', headerName: 'ชื่อผู้บริหาร', width: 150 },
    { field: 'ceo_surname', headerName: 'นามสกุลผู้บริหาร', width: 150 },
    { field: 'agent_prefix', headerName: 'คำนำหน้าผู้แทนกรรมการ', width: 100 },
    { field: 'agent_name', headerName: 'ชื่อผู้แทนกรรมการ', width: 150 },
    { field: 'agent_surname', headerName: 'นามสกุลผู้แทนกรรมการ', width: 150 },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
            รายงาน ข้อมูลนักศึกษา
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Box>
              <Typography variant="h6" sx={{ color: '#2c3e50', fontWeight: 'medium' }}>ข้อมูลภาพรวม</Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={6} sm={12}>
                  <Typography variant="body1">จำนวนนักศึกษา เข้ารับการประเมิน: {overview.totalStudents} คน</Typography>
                </Grid>
              </Grid>
            </Box>
            <Button variant="contained" color="primary" onClick={handleDownloadCSV} sx={{ height: 'fit-content' }}>
              ดาวน์โหลด CSV
            </Button>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <DataGrid
            rows={students} // แทน data ด้วยข้อมูลของคุณ
            getRowId={(row) => row.s_id}
            columns={columns}
            pagination
            />
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
};

export default StudentReport
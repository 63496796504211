import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container, Typography, RadioGroup, FormControl, FormControlLabel,
  Radio, Button, Box, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Divider, LinearProgress, Tab, Tabs, Checkbox, Menu, MenuItem, IconButton, Snackbar, Alert
} from '@mui/material';
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import config from '../config';

const EvaluatorEvaluationForm = ({ sections = [], initialRatings }) => {
  //const navigate = useNavigate();
  const [ratings, setRatings] = useState({});
  const [progress, setProgress] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [columnVisibility, setColumnVisibility] = useState({ criteriaTitle: true, question: true, level: true });
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const { m_id_student, cr_id, s_id, round } = location.state || {};
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State สำหรับ Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State สำหรับข้อความใน Snackbar

  // ตั้งค่าเริ่มต้นด้วย answer หากมีค่า
  useEffect(() => {
    const initialRatingsWithAnswers = {};
    sections.forEach((section) => {
      section.criteria.forEach((criteria) => {
        initialRatingsWithAnswers[criteria.question] = criteria.answer || ''; // ใช้ answer เป็นค่าเริ่มต้น ถ้ามี
      });
    });
    setRatings(initialRatingsWithAnswers);
  }, [sections]);

  useEffect(() => {
    const totalQuestions = sections.reduce((count, section) => count + section.criteria.length, 0);
    const answeredQuestions = Object.keys(ratings).filter((key) => ratings[key] !== '').length;
    setProgress((answeredQuestions / totalQuestions) * 100);
  }, [ratings, sections]);

  const handleRatingChange = (question, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [question]: value
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    window.location.reload(); // รีโหลดทั้งหน้า
    // Redirect ไปที่หน้า /evaluator-pending-job
    //navigate('/evaluator-pending-job', { replace: true });
  };

  const questionMap = {
    "๑.๑.๑ สามารถวิเคราะห์ความสอดคล้องของสาระการเรียนรู้กับมาตรฐานการเรียนรู้ของหลักสูตรแกนกลางและหลักสูตรสถานศึกษา": "c1_q01",
    "๑.๑.๒ สามารถวิเคราะห์ความสอดคล้องของสาระการเรียนรู้เพื่อพัฒนาผู้เรียนให้มีปัญญารู้คิดและมีความเป็นนวัตกร": "c1_q02",
    "๑.๒.๑ สามารถเขียนแผนการจัดการเรียนรู้เพื่อพัฒนาผู้เรียนให้มีปัญญารู้คิดและมีความเป็นนวัตกรรม": "c1_q03",
    "๑.๒.๒ สามารถจัดการเรียนรู้ให้เป็นไปตามแผนการจัดการเรียนรู้เพื่อพัฒนาผู้เรียนให้มีปัญญารู้คิดและมีความเป็นนวัตกร": "c1_q04",
    "๑.๓.๑ สามารถจัดกิจกรรมและสร้างบรรยากาศการเรียนรู้ให้ผู้เรียนมีความสุขในการเรียน": "c1_q05",
    "๑.๓.๒ สามารถจัดกิจกรรมและสร้างบรรยากาศการเรียนรู้ให้ผู้เรียนโดยตระหนักถึงสุขภาวะของผู้เรียน": "c1_q06",
    "๑.๔.๑ สามารถดูแล ช่วยเหลือ และพัฒนาผู้เรียนเป็นรายบุคคลตามศักยภาพ": "c1_q07",
    "๑.๔.๒ สามารถรายงานผลการพัฒนาคุณภาพผู้เรียนได้อย่างเป็นระบบ": "c1_q08",
    "๑.๕.๑ สามารถทําวิจัยที่สอดคล้องกับปัญหาของผู้เรียน": "c1_q09",
    "๑.๕.๒ สามารถประยุกต์ใช้เทคโนโลยีดิจิทัลในการจัดการเรียนรู้ เช่น CAI, google classroom, Kahoot เป็นต้น": "c1_q10",
    "๑.๖.๑ สามารถปฏิบัติงานร่วมกับผู้อื่นอย่างสร้างสรรค์": "c1_q11",
    "๑.๖.๒ มีส่วนร่วมในกิจกรรมการพัฒนาวิชาชีพ": "c1_q12",
    "๒.๑.๑ ร่วมมือกับผู้ปกครองในการพัฒนาผู้เรียนให้มีคุณลักษณะที่พึงประสงค์ของสถานศึกษา": "c2_q01",
    "๒.๑.๒ ร่วมมือกับผู้ปกครองในการแก้ปัญหาผู้เรียนให้มีคุณลักษณะที่พึงประสงค์ของสถานศึกษา": "c2_q02",
    "๒.๒.๑ สามารถสร้างเครือข่ายความร่วมมือกับผู้ปกครองเพื่อสนับสนุนการเรียนรู้ที่มีคุณภาพของผู้เรียน": "c2_q03",
    "๒.๒.๒ สามารถสร้างเครือข่ายความร่วมมือกับชุมชน เช่น ปราชญ์ชาวบ้าน หน่วยงานปกครองของท้องถิ่น เพื่อสนับสนุนการเรียนรู้ที่มีคุณภาพของผู้เรียน": "c2_q04",
    "๒.๓.๑ สามารถรายงานการศึกษาสภาพแวดล้อมของชุมชนโดยเลือกประเด็นศึกษา ได้แก่ ๑. วิทยากรในชุมชน ๒. ปราชญ์ชาวบ้านในชุมชน ๓. แหล่งเรียนรู้ในชุมชน ๔. วัฒนธรรมของชุมชุน ๕. เศรษฐกิจของชุมชน": "c2_q05",
    "๒.๓.๒ สามารถปฏิบัติตนในการอยู่ร่วมกับชุมชนได้อย่างเหมาะสม": "c2_q06",
    "๒.๔.๑ สามารถรายงานการศึกษาวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่นโดยเลือกศึกษาตามประเด็น ได้แก่ ๑.วิทยากรด้านวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่น ๒. ปราชญ์ชาวบ้านด้านวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่น ๓. แหล่งเรียนรู้ในชุมชนด้านวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่น ๔. การอนุรักษ์วัฒนธรรมและภูมิปัญญาในท้องถิ่น": "c2_q07",
    "๒.๔.๒ สามารถนําวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่น มาบูรณาการ ในการจัดการเรียนรู้ในชั้นเรียนตามประเด็น ได้แก่ ๑. องค์ความรู้ของวิทยากรด้านวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่น ๒. องค์ความรู้ของปราชญ์ชาวบ้านด้านวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่น ๓. องค์ความรู้จากแหล่งเรียนรู้ในชุมชนด้านวัฒนธรรมของชุมชนและภูมิปัญญาในท้องถิ่น": "c2_q08",
    "๓.๑.๑ มุ่งมั่นพัฒนาผู้เรียนให้เกิดการเรียนรู้ ทักษะปฏิบัติ และคุณลักษณะที่ดีงาม อย่างเต็มความสามารถด้วยวิธีการที่เหมาะสมกับระดับความสามารถและช่วงวัย": "c3_q01",
    "๓.๑.๒ รักเมตตา เอาใจใส่ ช่วยเหลือ และพัฒนาผู้เรียนอย่างเหมาะสมด้วยความบริสุทธิ์ใจ": "c3_q02",
    "๓.๒.๑ ส่งเสริมการเรียนรู้อย่างต่อเนื่องด้วยความเอาใจใส่": "c3_q03",
    "๓.๒.๒ ยอมรับความแตกต่างของผู้เรียนทางด้านเพศ เชื้อชาติ ศาสนา วัฒนธรรมและระดับการเรียนรู้": "c3_q04",
    "๓.๓.๑ กระตุ้นและเสริมสร้างแรงจูงใจในการเรียนรู้แก่ผู้เรียนโดยใช้การเสริมแรงทางบวก": "c3_q05",
    "๓.๓.๒ ส่งเสริมให้ผู้เรียนแสดงความสามารถและความคิดริเริ่มสร้างสรรค์อย่างเต็มศักยภาพ": "c3_q06",
    "๓.๔.๑ ติดตามข้อมูลข่าวสารการศึกษา สังคม การเมือง การปกครอง และเศรษฐกิจ และทันต่อการเปลี่ยนแปลง โดยสามารถนํามาปรับใช้/เชื่อมโยง กับเนื้อหาในการจัดการเรียนรู้ได้อย่างมีประสิทธิภาพส่วนหนึ่งในการออกแบบการจัดกิจกรรมการเรียนรู้ได้อย่างเหมาะสม": "c3_q07",
    "๓.๔.๒ นําแนวคิด เทคนิควิธีการ หรือความรู้ใหม่ๆ ที่น่าสนใจ มาประยุกต์ใช้เป็น": "c3_q08",
    "๓.๕.๑ นําแนวคิด เทคนิควิธีการ หรือความรู้ใหม่ๆ ที่น่าสนใจ มาประยุกต์ใช้เป็น": "c3_q09",
    "๓.๕.๒ ปฏิบัติตนโดยยึดหลักความเป็นธรรม เท่าเทียม และมีส่วนช่วยให้คนใน องค์กรอยู่ร่วมกันอย่างสันติ": "c3_q10",
    "๓.๖.๑ ปฏิบัติตนตามข้อตกลง กฎกติกาของโรงเรียนด้วยความสมัครใจ ทั้งในด้าน การปฏิบัติการสอน และการปฏิบัติหน้าที่อื่นในโรงเรียน": "c3_q11",
    "๓.๖.๒ ติดตามข้อมูลและปรับเปลี่ยนตนเองให้สอดคล้องการเปลี่ยนแปลงทาง วิชาชีพ วิทยาการ เศรษฐกิจ สังคม และการเมือง": "c3_q12",
    "๓.๗.๑ ศรัทธา ซื่อสัตย์ สุจริต และรับผิดชอบต่อวิชาชีพครู": "c3_q13",
    "๓.๗.๒ เป็นสมาชิกที่ดีขององค์กรวิชาชีพ": "c3_q14",
    "๓.๘.๑ ให้บริการด้วยความจริงใจและเสมอภาค": "c3_q15",
    "๓.๘.๒ ไม่เรียกรับหรือยอมรับผลประโยชน์จากการใช้ตําแหน่งหน้าที่โดยมิชอบ": "c3_q16",
    "๓.๙.๑ อุทิศตนเพื่อช่วยเหลือเพื่อนผู้ร่วมประกอบวิชาชีพภายใต้หลักการที่ถูกต้อง": "c3_q17",
    "๓.๙.๒ สร้างความสามัคคีในหมู่คณะ": "c3_q18",
    "๓.๑๐.๑ ริเริ่ม วางแผน หรือ เป็นผู้นําในการทํากิจกรรมเกี่ยวกับการอนุรักษ์และ พัฒนา เศรษฐกิจ สังคม ศาสนา ศิลปวัฒนธรรม ภูมิปัญญา หรือ สิ่งแวดล้อมโดยคํานึงถึงผลประโยชน์ของส่วนรวมเป็นสําคัญ": "c3_q19",
    "๓.๑๐.๒ ปฏิบัติตนตามกฎระเบียบของสังคมภายใต้ระบอบประชาธิปไตยอันมี พระมหากษัตริย์ทรงเป็นประมุขอย่างเคร่งครัด": "c3_q20",
    };

const handleSubmit = async (e) => {
  e.preventDefault();
  const token = localStorage.getItem('jwttoken');

  // ใช้ questionMap เพื่อแปลง answers ให้เป็นคีย์ที่สอดคล้องกับฐานข้อมูล
  const mappedAnswers = {};
  Object.keys(ratings).forEach((questionText) => {
      const baseKey = questionMap[questionText];
      if (baseKey) {
          // ใช้ round ปัจจุบันประกอบกับ baseKey เพื่อสร้างคีย์แบบไดนามิกที่ตรงกับฐานข้อมูล
          const [competency, question] = baseKey.split('_');
          const dbKey = `${competency}_r${round}_${question}`;
          mappedAnswers[dbKey] = ratings[questionText];
      }
  });

  const payload = {
      cr_id: cr_id || null,
      s_id: s_id || null,
      m_id_student: m_id_student || null,
      round: round || null,
      answers: mappedAnswers  // ส่ง answers ที่แมปแล้ว
  };

  console.log("Mapped Payload to be sent:", payload);

  try {
      const headers = {
          Authorization: `Bearer ${token}`
      };

      if (cr_id) {
          await axios.put(`${config.backendUrl}/job/submitEvaluation`, payload, { headers });
          console.log('Record updated successfully');
          setSnackbarMessage('บันทึกข้อมูลสำเร็จ');
          setSnackbarOpen(true);  // แสดง Snackbar เมื่อบันทึกสำเร็จ
      } else {
          await axios.post(`${config.backendUrl}/job/submitEvaluation`, payload, { headers });
          console.log('Record inserted successfully');
          setSnackbarMessage('บันทึกข้อมูลสำเร็จ');
          setSnackbarOpen(true);  // แสดง Snackbar เมื่อบันทึกสำเร็จ
           // Redirect และ reload ไปที่หน้า /evaluator-pending-job
          window.location.href = '/evaluator-pending-job';
      }
  } catch (error) {
      console.error('Error submitting evaluation:', error);
      setSnackbarMessage('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
      setSnackbarOpen(true); // เปิด Snackbar เมื่อเกิดข้อผิดพลาด
  }
};

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const calculateRowSpan = (criteriaList) => {
    const rowSpanData = {};
    criteriaList.forEach((criteria, index) => {
      if (index === 0 || criteria.criteriaTitle !== criteriaList[index - 1].criteriaTitle) {
        const spanCount = criteriaList.filter((c) => c.criteriaTitle === criteria.criteriaTitle).length;
        rowSpanData[index] = spanCount;
      }
    });
    return rowSpanData;
  };

  const toggleColumn = (column) => {
    setColumnVisibility((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
      <Paper elevation={6} sx={{ padding: 4, backgroundColor: '#f8f9fa', borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold', textAlign: 'center' ,fontSize: '2rem'}}>
          แบบประเมินสมรรถนะวิชาชีพครู
        </Typography>

        <Box sx={{ width: '100%', mt: 2, mb: 4 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1,fontSize: '1.1rem' }}>
            {Math.round(progress)}% เสร็จสิ้น
          </Typography>
        </Box>

        <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        variant="scrollable" 
        scrollButtons="auto" 
        //sx={{
        //    maxWidth: '100%',
        //    '& .MuiTabs-flexContainer': {
        //      justifyContent: 'center' // จัดศูนย์แท็บเมื่อขนาดหน้าจอใหญ่
        //    }
        //  }}
        >
          {sections.map((section, index) => (
            <Tab key={index} label={section.sectionTitle} sx={{ textAlign: 'center', mt: 1, fontSize: '0.9rem'}}/>
          ))}
        </Tabs>

        <Box sx={{ textAlign: 'right', mt: 2 }}>
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem>
              <Checkbox
                checked={columnVisibility.criteriaTitle}
                onChange={() => toggleColumn('criteriaTitle')}
              />
              แสดงสมรรถนะหลัก
            </MenuItem>
            {/*<MenuItem>
              <Checkbox
                checked={columnVisibility.question}
                onChange={() => toggleColumn('question')}
              />
              แสดงตัวบ่งชี้
            </MenuItem>*/}
            {/*<MenuItem>
              <Checkbox
                checked={columnVisibility.level}
                onChange={() => toggleColumn('level')}
              />
              แสดงระดับคะแนน
            </MenuItem>*/}
          </Menu>
        </Box>

        <form onSubmit={handleSubmit}>
          {sections.map((section, index) => (
            <Box key={index} hidden={activeTab !== index} sx={{ mb: 4 }}>
              <Typography variant="h5" gutterBottom sx={{ mt: 4, color: '#2c3e50', fontWeight: 'bold' }}>
                {section.sectionTitle}
              </Typography>
              <Divider sx={{ mb: 2 }} />

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columnVisibility.criteriaTitle && <TableCell sx={{ width: '35%' , fontSize: '1rem' }}>สมรรถนะหลัก</TableCell>}
                      {columnVisibility.question && <TableCell sx={{ width: '45%', fontSize: '1rem' }}>ตัวบ่งชี้</TableCell>}
                      {columnVisibility.level && <TableCell sx={{ width: '20%', fontSize: '1rem', whiteSpace: 'nowrap' }}>ระดับคะแนน</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {section.criteria.map((criteria, criteriaIndex) => {
                      const rowSpanData = calculateRowSpan(section.criteria);
                      const shouldShowCriteriaTitle = rowSpanData[criteriaIndex] !== undefined;
                      return (
                        <TableRow key={criteria.question}>
                          {columnVisibility.criteriaTitle && shouldShowCriteriaTitle && (
                            <TableCell rowSpan={rowSpanData[criteriaIndex]} sx={{fontSize: '1rem'}}>
                              {criteria.criteriaTitle}
                            </TableCell>
                          )}
                          {columnVisibility.question && <TableCell sx={{fontSize: '1rem', minWidth: '250px'}}>{criteria.question}</TableCell>}
                          {columnVisibility.level && (
                            <TableCell>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  sx={{ flexWrap: 'nowrap', minWidth: '300px' }}
                                  value={ratings[criteria.question] || ''}
                                  onChange={(e) => handleRatingChange(criteria.question, e.target.value)}
                                >
                                  {["5", "4", "3", "2", "1"].map((level) => (
                                    <FormControlLabel
                                      key={level}
                                      value={level}
                                      control={<Radio />}
                                      label={level}
                                    />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}

          <Box sx={{ width: '100%', mt: 4, mb: 4 }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography variant="body2" sx={{ textAlign: 'center', mt: 1, fontSize: '1.1rem' }}>
              {Math.round(progress)}% เสร็จสิ้น
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 4}}>
            <Button type="submit" variant="contained" color="primary" sx={{ fontSize: '0.9rem' }} >
              บันทึกผลการประเมิน
            </Button>
          </Box>
        </form>
      </Paper>
      {/* Snackbar สำหรับแสดงข้อความแจ้งเตือน */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('สำเร็จ') ? 'success' : 'error'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EvaluatorEvaluationForm;
// src/pages/StudentProfileUpdate.js
import React, { useState, useEffect } from 'react';
import { LinearProgress, Container, Typography, TextField, Button, Box, Paper, MenuItem, Select, InputLabel, FormControl, Divider, Snackbar, Alert, Grid } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import config from '../config'; // นำเข้า config ที่เก็บ URL
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const StudentProfileUpdate = () => {
  const navigate = useNavigate();
  //Handle session login
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    
    if (!token) {
      // ถ้าไม่มี token ใน localStorage ให้เปลี่ยนเส้นทางไปยังหน้าหลัก
      navigate('/');
    } else {
      try {
        const decodedToken = jwtDecode(token);
        
        // ตรวจสอบ expiration time
        const currentTime = Date.now() / 1000; // เวลาปัจจุบันในหน่วยวินาที
        if (decodedToken.exp < currentTime) {
          // ถ้า token หมดอายุ ให้ลบ token แล้วเปลี่ยนเส้นทางไปหน้าหลัก
          localStorage.removeItem('jwttoken');
          navigate('/');
        }
      } catch (error) {
        console.error('Invalid token:', error);
        // ถ้า token ไม่ถูกต้อง ลบ token แล้วเปลี่ยนเส้นทางไปหน้าหลัก
        localStorage.removeItem('jwttoken');
        navigate('/');
      }
    }
  }, [navigate]);

  //const [schoolAffiliation, setSchoolAffiliation] = useState('');
  //const [otherAffiliation, setOtherAffiliation] = useState('');
  const [provinces, setProvinces] = useState([]); // สำหรับเก็บข้อมูลจังหวัด
  const [districts, setDistricts] = useState([]); // สำหรับเก็บข้อมูลอำเภอ
  const [selectedProvince, setSelectedProvince] = useState(''); // จังหวัดที่เลือก
  const [selectedDistrict, setSelectedDistrict] = useState(''); // อำเภอที่เลือก
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  //const predefinedAffiliations = [
  //  "สำนักงานคณะกรรมการสถานศึกษาขั้นพื้นฐาน (สพฐ.)",
  //  "สำนักงานคณะกรรมการการอาชีวศึกษา (สอศ.)"
  //];
  const [progress, setProgress] = useState(0);
  const [evaluators, setEvaluators] = useState({
    supervisor: [],
    mentor: [],
    administrator: [],
    boardRepresentative: [],
  });
  const [profileData, setProfileData] = useState({
    s_id: '',
    s_prefix: '',
    s_name: '',
    s_surname: '',
    s_citizen_id: '',
    s_code: '',
    s_phone: '',
    s_email: '',
    s_institution_name: '',
    s_institution_affiliation: '',
    s_education_status: '',
    s_education_level: '',
    s_teaching_school: '',
    s_school_affiliation: '',
    s_school_size: '',
    s_teaching_province: '',
    s_teaching_district: '',
    s_teaching_subject: '',
    s_teaching_subject_group: '',
    s_major_subject: '',
    s_major_subject_relation: '',
    supervisor: '',
    supervisorName: '',
    mentor: '',
    mentorName: '',
    administrator: '',
    administratorName: '',
    boardRepresentative: '',
    boardRepresentativeName: ''
  });

  const [errors, setErrors] = useState({
    s_citizen_id: '',
    s_phone: ''
  });

  // ฟังก์ชันตรวจสอบแต่ละฟิลด์
  const validateField = (field, value) => {
    if (field === 's_citizen_id' && !/^\d{13}$/.test(value)) {
      return 'กรุณากรอกเลขบัตรประชาชน 13 หลัก';
    }
    if (field === 's_phone' && !/^\d{10}$/.test(value)) {
      return 'กรุณากรอกเบอร์โทร 10 หลัก';
    }
    return '';
  };


  // ฟังก์ชันสำหรับดึงข้อมูลอำเภอ
  const fetchDistricts = async (province) => {
    try {
      const response = await axios.get(`${config.backendUrl}/province/get_district`, {
        params: { province },
      });
      setDistricts(response.data);
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };

  //const handleChange = (field) => (event) => {
  //  setProfileData({ ...profileData, [field]: event.target.value });
  //};

  // จัดการการเปลี่ยนแปลงของแต่ละฟิลด์ พร้อมการตรวจสอบ
  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: validateField(field, value),
    }));
    setProfileData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  

  /*const handleSchoolAffiliationChange = (event) => {
  const value = event.target.value;
    //setSchoolAffiliation(value);
    // ล้างค่า otherAffiliation เมื่อเลือกกลับไปที่ตัวเลือกอื่น
    if (value !== 'other') {
      setOtherAffiliation('');
    }
   setSchoolAffiliation(event.target.value);
   setProfileData({
     ...profileData,
     s_school_affiliation: event.target.value,
   });
  };*/

  /*const handleOtherAffiliationChange = (event) => {
    const value = event.target.value;
    // ถ้าผู้ใช้ลบเนื้อหาทั้งหมดออก ให้คงค่าเป็น "other" เพื่อให้กรอกใหม่ได้
    setSchoolAffiliation(value || "other");
    setProfileData({
      ...profileData,
      s_school_affiliation: value,
    });
  };

  const handleOtherAffiliationBlur = () => {
    // ย้อนกลับไปที่ Select พร้อมแสดงค่าที่กรอกไว้ใน TextField
    setSchoolAffiliation(otherAffiliation || 'other');
  };
  */
  //progress bar
  useEffect(() => {
    const calculateProgress = () => {
      const totalFields = 24; // จำนวนฟิลด์ทั้งหมด (นับเฉพาะฟิลด์ที่ต้องกรอก)
      let filledFields = 0;

      if (profileData.s_prefix) filledFields++;
      if (profileData.s_name) filledFields++;
      if (profileData.s_surname) filledFields++;
      if (profileData.s_citizen_id) filledFields++;
      if (profileData.s_code) filledFields++;
      if (profileData.s_phone) filledFields++;
      if (profileData.s_email) filledFields++;
      if (profileData.s_institution_name) filledFields++;
      if (profileData.s_institution_affiliation) filledFields++;
      if (profileData.s_education_status) filledFields++;
      if (profileData.s_education_level) filledFields++;
      if (profileData.s_teaching_school) filledFields++;
      if (profileData.s_school_affiliation) filledFields++;
      if (profileData.s_teaching_province) filledFields++;
      if (profileData.s_teaching_district) filledFields++;
      if (profileData.s_school_size) filledFields++;
      if (profileData.s_teaching_subject) filledFields++;
      if (profileData.s_teaching_subject_group) filledFields++;
      if (profileData.s_major_subject) filledFields++;
      if (profileData.s_major_subject_relation) filledFields++;
      if (profileData.supervisor) filledFields++;
      if (profileData.mentor) filledFields++;
      if (profileData.administrator) filledFields++;
      if (profileData.boardRepresentative) filledFields++;
      setProgress((filledFields / totalFields) * 100);
    };
  
    calculateProgress();
  }, [profileData, selectedProvince, selectedDistrict]); // ให้คำนวณใหม่ทุกครั้งที่มีการเปลี่ยนแปลงในฟิลด์ข้อมูล

  // ดึงข้อมูลจังหวัดเมื่อโหลดหน้า
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/province/get_province`);
        setProvinces(response.data);
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    };
    fetchProvinces();
  }, []);

  // ดึงข้อมูลอำเภอตามจังหวัดที่เลือก
  useEffect(() => {
    if (selectedProvince) {
      const fetchDistricts = async () => {
        try {
          const response = await axios.get(`${config.backendUrl}/province/get_district`, {
            params: { province: selectedProvince },
          });
          setDistricts(response.data);
          setSelectedDistrict(''); // รีเซ็ตอำเภอเมื่อเปลี่ยนจังหวัด
        } catch (error) {
          console.error('Error fetching districts:', error);
        }
      };
      fetchDistricts();
    } else {
      setDistricts([]); // ถ้าไม่เลือกจังหวัด ให้ล้างข้อมูลอำเภอ
    }
  }, [selectedProvince]);

  // ดึงข้อมูล profile เมื่อโหลดหน้า
    useEffect(() => {
      const token = localStorage.getItem('jwttoken');
      const fetchProfileData = async () => {
        try {
          const response = await axios.get(`${config.backendUrl}/students/get_profile`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const profile = response.data;
          setProfileData({
            ...profile,
            //supervisor: profile.supervisor?.m_id || "",
            //supervisorName: profile.supervisor?.m_name || "", 
            //mentor: profile.mentor?.m_id || "",
            //mentorName: profile.mentor?.m_name || "", 
            //administrator: profile.administrator?.m_id || "",
            //administratorName: profile.administrator?.m_name || "", 
            //boardRepresentative: profile.boardRepresentative?.m_id || "",
            //boardRepresentativeName: profile.boardRepresentative?.m_name || ""
          });
          //console.log("Fetched profileData:", profile); // ตรวจสอบข้อมูล profileData

        //}
        // ตั้งค่า selectedDistrict เป็นค่าจากฐานข้อมูลหากมี
          if (profile.s_teaching_district) {
            setSelectedDistrict(profile.s_teaching_district);
           }
          
           //console.log(profile.s_id);
          // ตั้งค่า province และดึงอำเภอหากจังหวัดมีข้อมูล
          if (profile.s_teaching_province) {
            setSelectedProvince(profile.s_teaching_province);
            fetchDistricts(profile.s_teaching_province); // ดึงข้อมูลอำเภอเมื่อมีจังหวัดจาก backend
            //console.log(profile.s_teaching_province);

          }
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      };
      fetchProfileData();
    }, []);

 // ฟังก์ชันดึงข้อมูลผู้ประเมิน (เรียกหลังจาก profileData.s_id มีค่า)
useEffect(() => {
  if (profileData.s_id) {  // ตรวจสอบว่า profileData.s_id มีค่าแล้ว
    //console.log("s_id is set, calling fetchEvaluators:", profileData.s_id); // ตรวจสอบค่า s_id ก่อนเรียก fetchEvaluators
    const token = localStorage.getItem('jwttoken');
    const fetchEvaluators = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/member/get_evaluator/${profileData.s_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        setEvaluators({
          supervisor: response.data["อาจารย์นิเทศก์"]?.[0] || [], 
          mentor: response.data["ครูพี่เลี้ยง"]?.[0] || [],
          administrator: response.data["ผู้บริหารสถานศึกษา"]?.[0] || [],
          boardRepresentative: response.data["ผู้แทนกรรมการสถานศึกษา"]?.[0] || []
        });
        //console.log("Fetched evaluators:", response.data); // ตรวจสอบข้อมูลผู้ประเมิน
      } catch (error) {
        console.error('Error fetching evaluators data:', error);
      }
    };
    fetchEvaluators();
  }
}, [profileData.s_id]); // เรียกใช้งานเมื่อ profileData.s_id มีการอัปเดต

  // ฟังก์ชันบันทึกข้อมูล
  const handleSave = async () => {
    const token = localStorage.getItem('jwttoken');
    // ตรวจสอบค่า selectedDistrict ก่อนบันทึก
    const districtToSave = selectedDistrict || profileData.s_teaching_district;
    //console.log("อำเภอที่จะบันทึก:", districtToSave);
  
    if (!errors.s_citizen_id && !errors.s_phone) { 
      try {
        const updatedProfileData = {
          ...profileData,
          s_teaching_district: districtToSave,
          //s_school_affiliation: schoolAffiliation,
          //s_school_affiliation_detail: schoolAffiliation === 'other' ? otherAffiliation : '', // ใช้ค่า otherAffiliation หาก schoolAffiliation เป็น 'other'
        };
              // แสดงข้อมูลที่ส่งให้ backend ใน console
      //console.log("Data being sent to backend:", updatedProfileData);
        //console.log(profileData.s_teaching_district);
        const response = await axios.put(`${config.backendUrl}/students/update_profile`, updatedProfileData, {
        //const response = await axios.post(`${config.backendUrl}/test_input`, updatedProfileData, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        // อัปเดต Snackbar ด้วยข้อความจาก backend
        setSnackbar({
          open: true,
          message: response.data.message || 'บันทึกข้อมูลสำเร็จ',
          severity: 'success',
        });
      
      } catch (error) {
        console.error('Error updating profile:', error);
  
        // แสดง Snackbar เมื่อเกิดข้อผิดพลาด
        setSnackbar({
          open: true,
          message: error.response?.data?.message || 'เกิดข้อผิดพลาดในการบันทึกข้อมูล',
          severity: 'error',
        });
      }
    } else {
      // แจ้งเตือนเมื่อข้อมูลมีข้อผิดพลาด
      setSnackbar({
        open: true,
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        severity: 'warning',
      });
    }
  };

  // ฟังก์ชันปิด snackbar และรีโหลดหน้า
    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbar({ ...snackbar, open: false });
      //setSnackbarOpen(false);
      window.location.reload(); // รีโหลดทั้งหน้า
    };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />

      {/* Content */}
      <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={6} sx={{ padding: 6, backgroundColor: '#f8f9fa', borderRadius: 2, width: '100%', maxWidth: '90%', margin: 'auto' }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold', textAlign: 'center' }}>
            สำหรับนักศึกษา
          </Typography>
         {/* คำอธิบายการกรอกข้อมูล */}
         <Typography variant="body1" gutterBottom sx={{ color: '#7f8c8d', mb: 3, textAlign: 'left' }}>
            กรุณากรอกข้อมูลให้ครบถ้วน
          </Typography>

          {/* ข้อมูลส่วนตัว */}
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', color: '#2c3e50', fontWeight: 'bold', mt: 3 }}>
            ข้อมูลส่วนบุคคล
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel>คำนำหน้า</InputLabel>
                <Select value={profileData.s_prefix || ''} onChange={handleChange('s_prefix')}>
                  <MenuItem value="นาย">นาย</MenuItem>
                  <MenuItem value="นางสาว">นางสาว</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="ชื่อ" variant="outlined" value={profileData.s_name} onChange={handleChange('s_name')}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="นามสกุล" variant="outlined" value={profileData.s_surname} onChange={handleChange('s_surname')}/>
            </Grid>
          <Grid item xs={12} sm={6}>
              <TextField fullWidth label="เลขบัตรประชาชน" variant="outlined" value={profileData.s_citizen_id} onChange={handleChange('s_citizen_id')} error={!!errors.s_citizen_id} helperText={errors.s_citizen_id}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="รหัสนักศึกษา" variant="outlined" value={profileData.s_code} onChange={handleChange('s_code')}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="เบอร์โทร" variant="outlined" value={profileData.s_phone} onChange={handleChange('s_phone')} error={!!errors.s_phone} helperText={errors.s_phone}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="อีเมล" variant="outlined" value={profileData.s_email} onChange={handleChange('s_email')} disabled/>
            </Grid>
          </Grid>

          {/* ข้อมูลสถานศึกษา */}
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', color: '#2c3e50', fontWeight: 'bold', mt: 3 }}>
            ข้อมูลการศึกษา
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="ชื่อสถาบันอุดมศึกษา" variant="outlined" value={profileData.s_institution_name} onChange={handleChange('s_institution_name')}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>สถาบันอุดมศึกษาสังกัด</InputLabel>
                <Select
                  value={profileData.s_institution_affiliation || ''}
                  onChange={handleChange('s_institution_affiliation')}
                >
                  <MenuItem value="มหาวิทยาลัยเทคโนโลยีราชมงคล">มหาวิทยาลัยเทคโนโลยีราชมงคล</MenuItem>
                  <MenuItem value="มหาวิทยาลัยในกำกับรัฐ">มหาวิทยาลัยในกำกับรัฐ</MenuItem>
                  <MenuItem value="มหาวิทยาลัยราชภัฎ">มหาวิทยาลัยราชภัฎ</MenuItem>
                  <MenuItem value="มหาวิทยาลัยเอกชน">มหาวิทยาลัยเอกชน</MenuItem>
                  <MenuItem value="วิทยาลัยเอกชน">วิทยาลัยเอกชน</MenuItem>
                  <MenuItem value="สถาบันเอกชน">สถาบันเอกชน</MenuItem>
                  <MenuItem value="สถานศึกษานอกสังกัดกระทรวงการอุดมศึกษาฯ">สถานศึกษานอกสังกัดกระทรวงการอุดมศึกษาฯ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>สถานะการศึกษา</InputLabel>
                <Select value={profileData.s_education_status || ''} onChange={handleChange('s_education_status')}>
                  <MenuItem value="อยู่ระหว่างศึกษา">อยู่ระหว่างศึกษา</MenuItem>
                  <MenuItem value="สำเร็จการศึกษา">สำเร็จการศึกษา</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>ระดับการศึกษา</InputLabel>
                <Select value={profileData.s_education_level || ''} onChange={handleChange('s_education_level')}>
                  <MenuItem value="ปริญญาตรีทางการศึกษา">ปริญญาตรีทางการศึกษา</MenuItem>
                  <MenuItem value="ประกาศนีย์บัตรบัณฑิตวิชาชีพครู">ประกาศนียบัตรบัณฑิตวิชาชีพครู</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* ข้อมูลการสอน */}
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', color: '#2c3e50', fontWeight: 'bold', mt: 3 }}>
            ข้อมูลการสอน
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <TextField fullWidth label="ชื่อสถานศึกษาที่ปฏิบัติการสอน" variant="outlined" value={profileData.s_teaching_school} onChange={handleChange('s_teaching_school')} disabled/>
            </Grid>

            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <InputLabel>สถานศึกษาสังกัด</InputLabel>
                <Select
                  value={profileData.s_school_affiliation || ''}
                  onChange={handleChange('s_school_affiliation')}
                >
                  <MenuItem value="สำนักงานคณะกรรมการสถานศึกษาขั้นพื้นฐาน (สพฐ.)">สำนักงานคณะกรรมการสถานศึกษาขั้นพื้นฐาน (สพฐ.)</MenuItem>
                  <MenuItem value="สำนักงานคณะกรรมการการอาชีวศึกษา (สอศ.)">สำนักงานคณะกรรมการการอาชีวศึกษา (สอศ.)</MenuItem>
                  <MenuItem value="สำนักงานสงเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย (กศน)">สำนักงานสงเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย (กศน)</MenuItem>
                  <MenuItem value="สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน (สช.)">สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน (สช.)</MenuItem>
                  <MenuItem value="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม (อว.)">กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม (อว.)</MenuItem>
                  <MenuItem value="องค์การมหาชน (โรงเรียนมหิดลวิทยานุสรณ์)">องค์การมหาชน (โรงเรียนมหิดลวิทยานุสรณ์)</MenuItem>
                  <MenuItem value="สำนักการศึกษากรุงเทพมหานคร (กทม.)">สำนักการศึกษากรุงเทพมหานคร (กทม.)</MenuItem>
                  <MenuItem value="องค์กรปกครองส่วนท้องถิ่น (กถ.)">องค์กรปกครองส่วนท้องถิ่น (กถ.)</MenuItem>
                  <MenuItem value="มหาวิทยาลัยการกีฬาแห่งชาติ กระทรวงการท่องเที่ยวและกีฬา (กก.)">มหาวิทยาลัยการกีฬาแห่งชาติ กระทรวงการท่องเที่ยวและกีฬา (กก.)</MenuItem>
                  <MenuItem value="สำนักงานพระพุทธศาสนาแห่งชาติ (พส.)">สำนักงานพระพุทธศาสนาแห่งชาติ (พส.)</MenuItem>
                  <MenuItem value="สถาบันบัณฑิตพัฒนศิลป์">สถาบันบัณฑิตพัฒนศิลป์</MenuItem>
                  <MenuItem value="สำนักบริหารการศึกษาพิเศษ (สศศ.)">สำนักบริหารการศึกษาพิเศษ (สศศ.)</MenuItem>
                  <MenuItem value="กองบัญชาการตำรวจตระเวณชายแดน (ตชด.)">กองบัญชาการตำรวจตระเวณชายแดน (ตชด.)</MenuItem>
                  <MenuItem value="สำนักงานปลัดกระทรวงศึกษาธิการ (สป.)">สำนักงานปลัดกระทรวงศึกษาธิการ (สป.)</MenuItem>
                  <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel>ขนาดสถานศึกษา</InputLabel>
                <Select value={profileData.s_school_size || ''} onChange={handleChange('s_school_size')}>
                  <MenuItem value="เล็ก">เล็ก</MenuItem>
                  <MenuItem value="กลาง">กลาง</MenuItem>
                  <MenuItem value="ใหญ่">ใหญ่</MenuItem>
                  <MenuItem value="ใหญ่พิเศษ">ใหญ่พิเศษ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>จังหวัด</InputLabel>
              <Select
                value={profileData.s_teaching_province}
                onChange={(event) => {
                  handleChange('s_teaching_province')(event);
                  setSelectedProvince(event.target.value);
                }}
              >
                {provinces.map((province) => (
                  <MenuItem key={province} value={province}>
                    {province}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!selectedProvince && !profileData.s_teaching_province}>
              <InputLabel>อำเภอ</InputLabel>
              <Select
                value={selectedDistrict || profileData.s_teaching_district || ''}
                onChange={(e) => {handleChange('s_teaching_district')(e);setSelectedDistrict(e.target.value);}}
              >
                <MenuItem value="">
                  <em>เลือกอำเภอ</em>
                </MenuItem>
                {districts.map((district) => (
                  <MenuItem key={district} value={district}>
                    {district}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="วิชาที่สอน" variant="outlined" value={profileData.s_teaching_subject} onChange={handleChange('s_teaching_subject')}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>กลุ่มวิชา</InputLabel>
                <Select
                  value={profileData.s_teaching_subject_group || ''}
                  onChange={handleChange('s_teaching_subject_group')}
                >
                  <MenuItem value="การศึกษาปฐมวัย">การศึกษาปฐมวัย</MenuItem>
                  <MenuItem value="การประถมศึกษา">การประถมศึกษา</MenuItem>
                  <MenuItem value="การศึกษาพิเศษ">การศึกษาพิเศษ</MenuItem>
                  <MenuItem value="การศึกษานอกระบบโรงเรียน">การศึกษานอกระบบโรงเรียน</MenuItem>
                  <MenuItem value="วิชาเฉพาะ ตามหลักสูตรการศึกษาขั้นพื้นฐาน จำนวน 35 กลุ่มวิชา">วิชาเฉพาะ ตามหลักสูตรการศึกษาขั้นพื้นฐาน จำนวน 35 กลุ่มวิชา</MenuItem>
                  <MenuItem value="วิชาเฉพาะ ตามหลักสูตรประกาศนียบัตรวิชาชีพ จำนวน 29 กลุ่มวิชา">วิชาเฉพาะ ตามหลักสูตรประกาศนียบัตรวิชาชีพ จำนวน 29 กลุ่มวิชา</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="สาขาวิชาเอก" variant="outlined" value={profileData.s_major_subject} onChange={handleChange('s_major_subject')}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>ความสัมพันธ์ของสาขาวิชาเอกกับวิชาที่สอน</InputLabel>
                <Select value={profileData.s_major_subject_relation} onChange={handleChange('s_major_subject_relation')} >
                  <MenuItem value="ตรงกับกลุ่มสาระการเรียนรู้">ตรงกับกลุ่มสาระการเรียนรู้</MenuItem>
                  <MenuItem value="สัมพันธ์กับกลุ่มสาระการเรียนรู้">สัมพันธ์กับกลุ่มสาระการเรียนรู้</MenuItem>
                  <MenuItem value="ไม่ตรงหรือไม่สัมพันธ์กับกลุ่มสาระการเรียนรู้  ">ไม่ตรงหรือไม่สัมพันธ์กับกลุ่มสาระการเรียนรู้</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

        {/* ข้อมูลผู้ประเมิน */}
        <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', color: '#2c3e50', fontWeight: 'bold', mt: 3 }}>
          ข้อมูลผู้ประเมิน
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>อาจารย์นิเทศก์</InputLabel>
              <Select
                value={profileData.supervisor || ""}
                onChange={handleChange('supervisor')}
              >
                {/* แสดงชื่อจาก profileData เป็นตัวเลือกแรก หากมี */}
                {profileData.supervisor && (
                  <MenuItem value={profileData.supervisor} sx={{ fontStyle: 'italic', color: 'gray' }}>{profileData.supervisorName}</MenuItem>
                )}
                {evaluators.supervisor.map((supervisor) => (
                  <MenuItem key={supervisor.m_id} value={supervisor.m_id}>
                    {supervisor.m_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>ครูพี่เลี้ยง</InputLabel>
              <Select
                value={profileData.mentor || ""}
                onChange={handleChange('mentor')}
              >
                {profileData.mentor && (
                  <MenuItem value={profileData.mentor} sx={{ fontStyle: 'italic', color: 'gray' }}>{profileData.mentorName}</MenuItem>
                )}
                {evaluators.mentor.map((mentor) => (
                  <MenuItem key={mentor.m_id} value={mentor.m_id}>
                    {mentor.m_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>ผู้บริหารสถานศึกษา</InputLabel>
              <Select
                value={profileData.administrator || ""}
                onChange={handleChange('administrator')}
              >
                {profileData.administrator && (
                  <MenuItem value={profileData.administrator} sx={{ fontStyle: 'italic', color: 'gray' }}>{profileData.administratorName}</MenuItem>
                )}
                {evaluators.administrator.map((administrator) => (
                  <MenuItem key={administrator.m_id} value={administrator.m_id}>
                    {administrator.m_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>ผู้แทนกรรมการสถานศึกษา</InputLabel>
              <Select
                value={profileData.boardRepresentative || ""}
                onChange={handleChange('boardRepresentative')}
              >
                {profileData.boardRepresentative && (
                  <MenuItem value={profileData.boardRepresentative} sx={{ fontStyle: 'italic', color: 'gray' }}>{profileData.boardRepresentativeName}</MenuItem>
                )}
                {evaluators.boardRepresentative.map((rep) => (
                  <MenuItem key={rep.m_id} value={rep.m_id}>
                    {rep.m_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
          {/* ProgressBar แสดงความคืบหน้า */}
          <Box sx={{ width: '100%', mt: 5, mb: 2 }}>
              <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5 }} />
              <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
                {Math.round(progress)}% กรอกข้อมูลสำเร็จ
              </Typography>
            </Box>
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 3 }} fullWidth>
            บันทึกข้อมูล
          </Button>
        </Paper>
        {/* Snackbar for feedback */}
      {/* Snackbar สำหรับแสดงข้อความ */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={1500}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      </Container>

      <Footer />
    </Box>
  );
};

export default StudentProfileUpdate;
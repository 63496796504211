// src/pages/OtpPage.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Paper, Snackbar, Alert, TextField } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const OtpPage = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [timer, setTimer] = useState(300);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleOtpChange = (index, value) => {
    let newOtp = [...otp];
    if (value.length === 6) {
      // กรณี Paste OTP ทั้งหมด
      newOtp = value.slice(0, 6).split('');
      setOtp(newOtp);
      handleVerifyOtp(newOtp.join(''));
    } else if (/^\d*$/.test(value)) {
      // กรณีกรอกตัวเลขทีละตัว
      newOtp[index] = value;
      setOtp(newOtp);
      if (newOtp.join('').length === 6) {
        handleVerifyOtp(newOtp.join(''));
      } else if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      if (otp[index] === '' && index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
      let newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    } else if (event.key === 'Enter' && otp.join('').length === 6) {
      handleVerifyOtp(otp.join(''));
    }
  };

  const handleVerifyOtp = async (otpCode) => {
    if (timer <= 0) {
      setSnackbarMessage('OTP หมดอายุ โปรดขอใหม่อีกครั้ง');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post(`${config.backendUrl}/auth/verify_otp`, { otp: otpCode });
      const { m_id, email, role, admin, jwttoken } = response.data.response;

      // บันทึกข้อมูลใน localStorage
      localStorage.setItem('m_id', m_id);
      localStorage.setItem('email', email);
      localStorage.setItem('role', role);
      localStorage.setItem('admin', admin);
      localStorage.setItem('jwttoken', jwttoken);

      setSnackbarMessage(response.data.message || 'เข้าสู่ระบบสำเร็จ');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      setTimeout(() => {
        if (role === 'นักศึกษา') {
          navigate('/student-profile-update');
        } else if (['อาจารย์นิเทศก์', 'ครูพี่เลี้ยง', 'ผู้บริหารสถานศึกษา', 'ผู้แทนกรรมการสถานศึกษา'].includes(role)) {
          navigate('/evaluator-pending-job');
        } else if (role === 'ผู้ดูแลระบบ') {
          navigate('/admin-dashboard');
        }
      }, 2000);
    } catch (err) {
      setSnackbarMessage(err.response?.data?.message || 'เกิดข้อผิดพลาดในการยืนยัน OTP');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5, mb: 5 }}>
        <Paper elevation={6} sx={{ padding: 4, backgroundColor: '#f8f9fa', borderRadius: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
            Enter OTP
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            OTP จะหมดอายุใน: {formatTime(timer)}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 3 }}>
            {otp.map((value, index) => (
              <TextField
                key={index}
                id={`otp-${index}`}
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                variant="outlined"
                inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'center', fontSize: '24px' } }}
                sx={{ width: 50 }}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ backgroundColor: '#d70075', ':hover': { backgroundColor: '#a40050' } }}
            onClick={() => handleVerifyOtp(otp.join(''))}
          >
            ส่ง OTP
          </Button>
        </Paper>
      </Container>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OtpPage;
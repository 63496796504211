import React, { useEffect, useState, useCallback } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { jwtDecode } from 'jwt-decode';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const token = localStorage.getItem('jwttoken');
  let name = '';
  let surname = '';
  let isAdmin = false;
  const role = token ? localStorage.getItem('role') : null;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (decodedToken.exp < currentTimestamp) {
        localStorage.clear();
        navigate('/');
      } else {
        name = decodedToken.name || '';
        surname = decodedToken.surname || '';
        isAdmin = decodedToken.isadmin === 'Y';
      }
    } catch (error) {
      console.error("Invalid token:", error);
      localStorage.clear();
      navigate('/');
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleHomeRedirect = useCallback(() => {
    if (isAdmin) {
      navigate('/admin-dashboard');
    } else {
      switch (role) {
        case 'นักศึกษา':
          navigate('/student-profile-update');
          break;
        default:
          navigate('/evaluator-pending-job');
      }
    }
  }, [isAdmin, navigate, role]);

  useEffect(() => {
    if (token && role) {
      const rolePaths = {
        public: ['/', '/login', '/otp'],
        'นักศึกษา': ['/student-profile-update'],
        'ผู้ดูแลระบบ': ['/admin-dashboard', '/member-management', '/student-report', '/sumscore-report', '/avgscore-report', '/stat-report', '/summary-report', '/evaluation-complete', '/evaluation-incomplete'],
        'อาจารย์นิเทศก์': ['/evaluator-pending-job', '/evaluator-evaluation', '/student-info', '/evaluation-score'],
        'ครูพี่เลี้ยง': ['/evaluator-pending-job', '/evaluator-evaluation'],
        'ผู้บริหารสถานศึกษา': ['/evaluator-pending-job', '/evaluator-evaluation'],
        'ผู้แทนกรรมการสถานศึกษา': ['/evaluator-pending-job', '/evaluator-evaluation'],
      };

      const allowedPaths = [
        ...rolePaths.public,
        ...(rolePaths[role] || []),
        ...(isAdmin ? rolePaths['ผู้ดูแลระบบ'] : [])
      ];

      if (!allowedPaths.includes(location.pathname)) {
        handleHomeRedirect();
      }
    }
  }, [location, role, isAdmin, token, handleHomeRedirect]);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <AppBar position="static" sx={{ backgroundColor: '#d70075' }}>
      <Toolbar>
        <Typography
          variant="h6"
          onClick={handleHomeRedirect}
          sx={{
            flexGrow: 1,
            color: '#ffffff',
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          ระบบประเมินสมรรถนะทางวิชาชีพครู
        </Typography>

        {token && (
          <>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
              {name && surname && (
                <Typography variant="body1" sx={{ color: '#ffffff', marginRight: 2 }}>
                  สวัสดี คุณ {name} {surname}
                </Typography>
              )}
              {role === 'นักศึกษา' && (
                <Button color="inherit" href="/student-profile-update" sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                  โปรไฟล์ของฉัน
                </Button>
              )}
              {['ครูพี่เลี้ยง', 'ผู้บริหารสถานศึกษา', 'ผู้แทนกรรมการสถานศึกษา'].includes(role) && (
                <Button color="inherit" href="/evaluator-pending-job" sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                  แบบประเมิน
                </Button>
              )}
              {['อาจารย์นิเทศก์'].includes(role) && (
              <>
                <Button color="inherit" href="/evaluator-pending-job" sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                  แบบประเมิน
                </Button>
                <Button color="inherit" href="/student-info" sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                  ข้อมูลนักศึกษา
              </Button>
              <Button color="inherit" href="/evaluation-score" sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                  คะแนนประเมิน
              </Button>
              </>
              )}
              {isAdmin && (
                <>
                  <Button color="inherit" href="/admin-dashboard" sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                    รายงาน
                  </Button>
                  <Button color="inherit" href="/member-management" sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                    จัดการสมาชิก
                  </Button>
                </>
              )}
              <Button color="inherit" onClick={handleLogout} sx={{ cursor: 'pointer', '&:hover': { color: '#f39c12', textDecoration: 'underline' } }}>
                ออกจากระบบ
              </Button>
            </Box>

            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton color="inherit" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {name && surname && (
                  <MenuItem disabled>{`สวัสดี คุณ ${name} ${surname}`}</MenuItem>
                )}
                {[
  role === 'นักศึกษา' && (
    <MenuItem key="student-profile" onClick={() => { navigate('/student-profile-update'); handleMenuClose(); }}>
      โปรไฟล์ของฉัน
    </MenuItem>
  ),
  ['ครูพี่เลี้ยง', 'ผู้บริหารสถานศึกษา', 'ผู้แทนกรรมการสถานศึกษา'].includes(role) && (
    <MenuItem key="evaluation" onClick={() => { navigate('/evaluator-pending-job'); handleMenuClose(); }}>
      แบบประเมิน
    </MenuItem>
  ),
  role === 'อาจารย์นิเทศก์' && [
    <MenuItem key="evaluation" onClick={() => { navigate('/evaluator-pending-job'); handleMenuClose(); }}>
      แบบประเมิน
    </MenuItem>,
    <MenuItem key="student-info" onClick={() => { navigate('/student-info'); handleMenuClose(); }}>
      ข้อมูลนักศึกษา
    </MenuItem>,
    <MenuItem key="evaluation-score" onClick={() => { navigate('/evaluation-score'); handleMenuClose(); }}>
      คะแนนประเมิน
    </MenuItem>
  ],
  isAdmin && [
    <MenuItem key="admin-dashboard" onClick={() => { navigate('/admin-dashboard'); handleMenuClose(); }}>
      รายงาน
    </MenuItem>,
    <MenuItem key="member-management" onClick={() => { navigate('/member-management'); handleMenuClose(); }}>
      จัดการสมาชิก
    </MenuItem>
  ],
  <MenuItem key="logout" onClick={() => { handleLogout(); handleMenuClose(); }}>
    ออกจากระบบ
  </MenuItem>
].filter(Boolean)}
              </Menu>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
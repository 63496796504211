// src/pages/AvgscoreReport.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Paper, Grid, Divider } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { saveAs } from 'file-saver';
import config from '../config';


const AvgReport = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [overview, setOverview] = useState({
    totalStudents: 0,
    totalSchools: 0,
    majorSubjects: {},
  });

  // ตรวจสอบการล็อกอินและโหลดข้อมูล
  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (!token) {
      navigate('/');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      if (Date.now() / 1000 > decodedToken.exp) {
        localStorage.removeItem('jwttoken');
        navigate('/');
        return;
      }
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('jwttoken');
      navigate('/');
      return;
    }

    // ดึงข้อมูลนักศึกษา
    const fetchData = async () => {
      const token = localStorage.getItem('jwttoken');
      if (!token) return;
      try {
        const response = await axios.get(`${config.backendUrl}/admin/avgscore_report`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStudents(response.data);
        calculateOverview(response.data);
      } catch (error) {
        console.error('Error fetching student report data:', error);
      }
    };

    fetchData();
  }, [navigate]);

  // ฟังก์ชันคำนวณภาพรวมข้อมูล
  const calculateOverview = (data) => {
    const totalStudents = data.length;
    setOverview({ totalStudents });
  };

// ฟังก์ชันดาวน์โหลด CSV
const handleDownloadCSV = () => {
  const csvHeader = [
      '\uFEFFรหัสนักศึกษา,เลขบัตรประชาชน,คำนำหน้า,ชื่อ,นามสกุล,คะแนนด้านการจัดการเรียนรู้เฉลี่ยรวม ทั้ง 3 ครั้ง,ผลการประเมินฯ ด้านการจัดการเรียนรู้ (ระดับคุณภาพฯ),คะแนนด้านความสัมพันธ์กับผู้ปกครองและชุมชนเฉลี่ยรวม ทั้ง 3 ครั้ง,ผลการประเมินฯ ด้านความสัมพันธ์กับผู้ปกครองและชุมชน (ระดับคุณภาพฯ),คะแนนด้านการปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพเฉลี่ยรวม ทั้ง 3 ครั้ง,ผลการประเมินฯ ด้านการปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ (ระดับคุณภาพฯ),คะแนนเฉลี่ยรวม 3 ด้าน, ผลการประเมินฯ รวม 3 ด้าน (ระดับคุณภาพฯ)'];
  
  const csvRows = students.map((student) => [
      student.s_code,
      student.s_citizen_id,
      student.s_prefix,
      student.s_name,
      student.s_surname,
      student.totalsumc1,
      student.transc1,
      student.totalsumc2,
      student.transc2,
      student.totalsumc3,
      student.transc3,
      student.total_avg,
      student.avg_trans
  ]);
  
  const csvContent = csvHeader.concat(csvRows.map(row => row.join(','))).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, 'avg_report.csv');
};

const columns = [
  { field: 's_id', headerName: 'รหัส', width: 100 },
  { field: 's_code', headerName: 'รหัสนักศึกษา', width: 150 },
  { field: 's_citizen_id', headerName: 'เลขบัตรประชาชน', width: 150 },
  { field: 's_prefix', headerName: 'คำนำหน้า', width: 100 },
  { field: 's_name', headerName: 'ชื่อ', width: 150 },
  { field: 's_surname', headerName: 'นามสกุล', width: 150 },
  { field: 'totalsumc1', headerName: 'คะแนนด้านการจัดการเรียนรู้เฉลี่ยรวม ทั้ง 3 ครั้ง', width: 250 },
  { field: 'transc1', headerName: 'ผลการประเมินฯ ด้านการจัดการเรียนรู้ (ระดับคุณภาพฯ)', width: 250 },
  { field: 'totalsumc2', headerName: 'คะแนนด้านความสัมพันธ์กับผู้ปกครองและชุมชนเฉลี่ยรวม ทั้ง 3 ครั้ง', width: 250 },
  { field: 'transc2', headerName: 'ผลการประเมินฯ ด้านความสัมพันธ์กับผู้ปกครองและชุมชน (ระดับคุณภาพฯ)', width: 250 },
  { field: 'totalsumc3', headerName: 'คะแนนด้านการปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพเฉลี่ยรวม ทั้ง 3 ครั้ง', width: 250 },
  { field: 'transc3', headerName: 'ผลการประเมินฯ ด้านการปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ (ระดับคุณภาพฯ)', width: 250 },
  { field: 'total_avg', headerName: 'คะแนนเฉลี่ยรวม 3 ด้าน', width: 200 },
  { field: 'avg_trans', headerName: 'ผลการประเมินฯ รวม 3 ด้าน (ระดับคุณภาพฯ)', width: 250 }
];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
            รายงาน คะแนนประเมินเฉลี่ย
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Box>
              <Typography variant="h6" sx={{ color: '#2c3e50', fontWeight: 'medium' }}>ข้อมูลภาพรวม</Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={6} sm={12}>
                  <Typography variant="body1">จำนวนนักศึกษา เข้ารับการประเมิน: {overview.totalStudents} คน</Typography>
                </Grid>
              </Grid>
            </Box>
            <Button variant="contained" color="primary" onClick={handleDownloadCSV} sx={{ height: 'fit-content' }}>
              ดาวน์โหลด CSV
            </Button>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2 }}>
          <DataGrid
            rows={students} // แทน data ด้วยข้อมูลของคุณ
            getRowId={(row) => row.s_id}
            columns={columns}
            pagination
            />
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
};

export default AvgReport